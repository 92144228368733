import React, { FC, ElementType, useState } from "react";

interface DescriptionBlockProps {
  as: ElementType;
  blockTitle: string;
  details: Array<string>;
  showDetailsClass?: string;
}

const DescriptionBlock: FC<DescriptionBlockProps> = ({
  as: Tag = "ul",
  blockTitle,
  details,
  showDetailsClass,
}) => {
  return (
    <Tag
      className={`
        ml-1 list-outside tracking-wide
        ${Tag === "ul" ? "list-disc" : "list-decimal"} ${showDetailsClass}`}
    >
      {blockTitle}
      {details?.map((message) => (
        <li key={message} className={`ml-6 ${showDetailsClass}`}>
          {message}
        </li>
      ))}
    </Tag>
  );
};

interface ResponsibilitiesBlockProps {
  responsibilities: {
    blockTitle: string;
    details: Array<string>;
  };
  detailsHeight: string;
  maxQuantity: number;
}

const ResponsibilitiesBlock: FC<ResponsibilitiesBlockProps> = ({
  responsibilities,
  detailsHeight,
  maxQuantity,
}) => {
  return (
    <ul className="ml-1 list-outside list-disc tracking-wide ">
      {responsibilities.blockTitle}
      {responsibilities.details.slice(0, maxQuantity).map((message) => (
        <li key={message} className="ml-6">
          {message}
        </li>
      ))}
      {responsibilities.details.slice(maxQuantity).map((message) => (
        <li key={message} className={`ml-6 ${detailsHeight}`}>
          {message}
        </li>
      ))}
    </ul>
  );
};
enum LIST_MAX_QUANTITY {
  DESKTOP = 4,
  MOBILE = 3,
}

interface DetailBoxProps {
  details: Array<DescriptionBlockProps>;
}

const DetailBox: FC<DetailBoxProps> = ({ details }) => {
  const [responsibilities, ...otherDetailsBlocks] = details;
  const [showDetails, updateShowDetails] = useState<boolean>(false);
  const detailsHeight = showDetails ? "h-auto opacity-100 visible" : "h-0 opacity-0 invisible";
  const viewDetailButtonAlign = showDetails ? "self-end" : "";

  return (
    <>
      <div className="hidden lg:block">
        <ResponsibilitiesBlock
          responsibilities={responsibilities}
          maxQuantity={LIST_MAX_QUANTITY.DESKTOP}
          detailsHeight={detailsHeight}
        />
      </div>
      <div className="block min-h-[6rem] lg:hidden">
        <ResponsibilitiesBlock
          responsibilities={responsibilities}
          maxQuantity={LIST_MAX_QUANTITY.MOBILE}
          detailsHeight={detailsHeight}
        />
      </div>

      <div className="flex">
        {/* box for other details */}
        <div className={`${detailsHeight} my-2 flex grow flex-col space-y-2 transition-opacity`}>
          {otherDetailsBlocks.map((detail) => (
            <DescriptionBlock
              {...detail}
              key={detail.blockTitle}
              showDetailsClass={detailsHeight}
            />
          ))}
        </div>
        {/* view detail button */}
        <div
          className={`${viewDetailButtonAlign} ${
            showDetails ? "mb-2" : ""
          } min-w-max cursor-pointer underline`}
          onClick={() => updateShowDetails((prev) => !prev)}
        >
          {showDetails ? "Hide" : "View"} details
        </div>
      </div>
    </>
  );
};

export { DetailBox };
