import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PageProps } from "gatsby";
import { useIntl } from "react-intl";

import withI18n from "i18n/withI18n";
import { Layout } from "components/Layout";
import { HeaderPlaceholder } from "components/Header";
import { CareersAtBTQ, CareerList } from "views/career/careersAtBTQ";
import { WorkingAtBTQ } from "views/career/workingAtBTQ";
// import BgImg from "./background";
import SEO from "components/seo";

import { AllSanityCareers } from "interface/sanityNode";
import COLOR from "constants/design";

import { useCareerAsCards } from "hooks/useCareerAsCards";

const careerQuery = graphql`
  query GetAllCareerInPage {
    allSanityCareer(sort: { order: DESC, fields: _updatedAt }) {
      nodes {
        _id
        _rawCareerPosition
        _rawCareerSlug
        _rawCareerResponsibilities
        _rawCareerRequirements
        _rawCareerInterviewProcess
        _rawCareerLocation
      }
    }
  }
`;
type CareerQuery = { allSanityCareer: AllSanityCareers };

const CareerPage = ({ location }: PageProps) => {
  const { nodes } = useStaticQuery<CareerQuery>(careerQuery).allSanityCareer;

  const { formatMessage } = useIntl();
  const { getCareerList } = useCareerAsCards();
  const list = getCareerList(nodes);

  return (
    <>
      <SEO
        title={formatMessage({ id: "CAREER_PAGE_TITLE" })}
        pathname={location.pathname}
        titlePrefix
        locale
      />
      <Layout background={{ color: COLOR.BACKGROUND_DARK }}>
        <HeaderPlaceholder />
        <CareersAtBTQ />
        <WorkingAtBTQ />
        <CareerList list={list} />
      </Layout>
    </>
  );
};

export default withI18n(CareerPage);
