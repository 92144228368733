import { LANGUAGE_FORMATTED_LIST } from "../../constants/i18n";
import { CareerNode } from "../../interface/sanityNode";

export const handleCareerRelatedData = (nodes: Array<CareerNode>) =>
  LANGUAGE_FORMATTED_LIST.map((lang) =>
    nodes.map(
      ({
        _id,
        _rawCareerPosition,
        _rawCareerSlug,
        _rawCareerResponsibilities,
        _rawCareerRequirements,
        _rawCareerInterviewProcess,
        _rawCareerLocation,
      }) => {
        const { minimum, preferred, niceToHave } = _rawCareerRequirements;
        return {
          _id,
          slug: _rawCareerSlug.current,
          location: _rawCareerLocation?.[lang]
            ? _rawCareerLocation?.[lang]
            : _rawCareerLocation?.en,
          positionName: _rawCareerPosition?.[lang]
            ? _rawCareerPosition?.[lang]
            : _rawCareerPosition?.en,
          responsibilities: _rawCareerResponsibilities?.[lang]
            ? _rawCareerResponsibilities?.[lang]
            : _rawCareerResponsibilities?.en,
          minimumRequirements: minimum[lang] ? minimum[lang] : minimum.en,
          preferredRequirements: preferred?.[lang] ? preferred?.[lang] : preferred?.en,
          niceToHaveRequirements: niceToHave?.[lang] ? niceToHave?.[lang] : niceToHave?.en,
          interviewProcess: _rawCareerInterviewProcess[lang]
            ? _rawCareerInterviewProcess[lang]
            : _rawCareerInterviewProcess.en,
        };
      }
    )
  );
