import React from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";

import { CareerData } from "interface/sanityNode";
import { JobPositionCard } from "./jobPositionCard";

const CareersAtBTQ = () => (
  <Section className="flex w-full flex-col items-center px-1.5 pt-24 pb-12 xl:px-0 xl:pt-48 xl:pb-24">
    {/* need i18n_code */}
    <Title className="items-center">
      <span className="text-primary">Careers</span> at BTQ
    </Title>
    {/* need i18n_code */}
    <p className="mt-4 text-center md:mt-6 md:mb-9 md:text-lg lg:mt-7 lg:mb-16 lg:text-2xl lg:leading-loose">
      <FormattedMessage id="CAREER_COMPANY_INTRO" />
    </p>
  </Section>
);

interface CareerListProps {
  list: Array<CareerData>;
}

const CareerList = ({ list }: CareerListProps) => (
  <Section className="flex w-full flex-col items-center pt-24 pb-[10vh]">
    {/* Card container */}
    <div className="flex w-full flex-col items-center space-y-4">
      {list.map((item) => (
        <JobPositionCard {...item} key={item._id} />
      ))}
    </div>
  </Section>
);

export { CareersAtBTQ, CareerList };
