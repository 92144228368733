import { useCallback, useContext } from "react";
import { i18nContext } from "context/i18n";

import type { CareerData, CareerNode } from "interface/sanityNode";
import { handleCareerRelatedData } from "utils/cms/career";
import { LANGUAGE_FORMATTED_LIST } from "constants/i18n";

const useCareerAsCards = () => {
  const { locale } = useContext(i18nContext);

  const getCareerList = useCallback(
    (rawData: Array<CareerNode>) => {
      const localeIndex = LANGUAGE_FORMATTED_LIST.indexOf(locale);
      const careers = handleCareerRelatedData(rawData);
      return (careers[localeIndex] || careers[0]) as Array<CareerData>;
    },
    [locale]
  );

  return { getCareerList };
};

export { useCareerAsCards };
