import React from "react";
import { FormattedMessage } from "react-intl";

import IconJobPosition from "assets/ic_job_position.inline.svg";

import { CustomLink } from "components/CustomLink";
import { Button } from "components/Button";
import { EXTERNAL_LINK } from "constants/externalLink";
import { SLUG } from "constants/slug";
import { DetailBox } from "../detailBox";
import { useDescriptionBlocks } from "./hooks/useDescriptionBlocks";

interface JobTitleProps {
  positionName: string;
  slug: string;
  className?: string;
}

const JobTitle = ({ positionName, slug, className }: JobTitleProps) => (
  <div className={`flex items-center gap-x-1.5 py-1 ${className}`}>
    {/* icon */}
    <IconJobPosition className="aspect-square h-5 flex-none md:h-7 xl:mr-2" />
    {/* text */}
    <CustomLink href={`${SLUG.CAREER}/${slug}`} className="flex flex-1 overflow-hidden">
      <span
        className="
          w-1 flex-1 truncate text-lg font-bold tracking-wide transition-colors duration-300 
        hover:text-primary sm:text-xl"
      >
        {positionName}
      </span>
    </CustomLink>
  </div>
);

const ApplyNowBtn = ({ className }: { className?: string }) => (
  <a href={`mailto:${EXTERNAL_LINK.JOB_APPLY_EMAIL}`} className={`${className}`}>
    <Button className="sm:px-10">
      <FormattedMessage id="CAREER_APPLICATION_APPLY_NOW" />
    </Button>
  </a>
);

interface JobPositionCardProps {
  positionName: string;
  slug: string;
  responsibilities: string[];
  minimumRequirements: string[];
  preferredRequirements: string[];
  niceToHaveRequirements: string[];
  interviewProcess: string[];
}

const JobPositionCard = ({ positionName, slug, ...descriptions }: JobPositionCardProps) => {
  const descriptionBlocks = useDescriptionBlocks(descriptions);

  return (
    <div
      className="
        flex w-full flex-col rounded-md bg-btq-card-bg-dark p-3 shadow-job-position-card 
        sm:py-8 sm:pl-9 sm:pr-14 lg:max-w-5xl lg:pr-20"
    >
      <div className="flex justify-between gap-x-1.5 sm:items-start">
        <JobTitle positionName={positionName} slug={slug} className="flex-1" />
        <ApplyNowBtn className="flex-none lg:translate-x-6" />
      </div>
      <DetailBox details={descriptionBlocks} />
    </div>
  );
};

export { JobPositionCard };
