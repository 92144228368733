import React, { HTMLProps } from "react";
import { FormattedMessage } from "react-intl";

import { screensWithoutPx, useBreakpoint } from "hooks/useBreakpoint";

import { Section } from "components/Section";
import { Title } from "components/Title";
import { SlideUp } from "components/Effects/SlideUp";
import { WorkingAtBTQCard, WorkingAtBTQCardProps } from "./WorkingAtBTQCard";

import iconSolveUniqueProblems from "assets/ic_solve_unique_problems.png";
import iconResearchDriven from "assets/ic_research_driven.png";
import iconBenefits from "assets/ic_benefits.png";

import design from "constants/design";

const workingAtBTQCard: Array<WorkingAtBTQCardProps> = [
  {
    image: iconSolveUniqueProblems,
    title: <FormattedMessage id="CAREER_WORKING_FEAT_1" />,
    description: <FormattedMessage id="CAREER_WORKING_DESCRIPTION_1" />,
  },
  {
    image: iconResearchDriven,
    title: <FormattedMessage id="CAREER_WORKING_FEAT_2" />,
    description: <FormattedMessage id="CAREER_WORKING_DESCRIPTION_2" />,
  },
  {
    image: iconBenefits,
    title: <FormattedMessage id="CAREER_WORKING_FEAT_3" />,
    description: <FormattedMessage id="CAREER_WORKING_DESCRIPTION_3" />,
  },
];

const SlideUpWrapper = ({ children }: HTMLProps<HTMLDivElement>) => {
  const breakpoint = useBreakpoint();
  const rootMargin = breakpoint > screensWithoutPx.lg ? -60 : 0;

  return (
    <SlideUp className="flex flex-col gap-y-5" rootMargin={rootMargin}>
      {children}
    </SlideUp>
  );
};

const WorkingAtBTQ = () => (
  <Section background={{ color: design.BACKGROUND_LIGHT }}>
    <div className="pt-8 pb-11 xl:gap-y-1 xl:pb-20 xl:pt-16">
      <SlideUpWrapper>
        <Title>
          <FormattedMessage id="CAREER_WORKING_TITLE" />
        </Title>
        <div className="flex w-full justify-between gap-x-4 overflow-auto xl:scrollbar-hide">
          {workingAtBTQCard.map(({ title, image, description }) => (
            <WorkingAtBTQCard key={image} title={title} image={image} description={description} />
          ))}
        </div>
      </SlideUpWrapper>
    </div>
  </Section>
);

export { WorkingAtBTQ };
