import React, { HTMLProps } from "react";

import DESIGN from "constants/design";

export type WorkingAtBTQCardProps = {
  title: JSX.Element;
  image: string;
  description: JSX.Element;
};

const CardWrapper = ({ children }: HTMLProps<HTMLDivElement>) => (
  <div
    className="
    relative
    w-[calc(100vw-4rem)] flex-none px-5 pb-3
    sm:w-[20rem] md:w-[25rem] xl:px-12 xl:pb-8"
  >
    {/* Card background */}
    <div
      className={`
      absolute inset-x-0 bottom-0
      h-[calc(100%-4rem)] w-full
      ${DESIGN.GRADIENT_GROUP_A} 
      rounded-xl md:rounded-3xl`}
    />
    {/* Contents */}
    <div className="isolate flex h-full w-full flex-col justify-end gap-y-3 md:gap-y-6">
      {children}
    </div>
  </div>
);

export const WorkingAtBTQCard = ({ image, title, description }: WorkingAtBTQCardProps) => (
  <CardWrapper>
    <img src={image} alt="" className="-mx-3 w-full" />
    <div className="flex h-32 w-full flex-col items-center gap-y-3 text-center md:gap-y-6">
      <span className="text-xl font-bold text-primary xl:text-2xl">{title}</span>
      <p>{description}</p>
    </div>
  </CardWrapper>
);
