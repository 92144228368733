import { ElementType, useMemo } from "react";
import { useIntl } from "react-intl";

type Details = Array<string>;

type DescriptionBlocksData = {
  responsibilities: Details;
  minimumRequirements: Details;
  preferredRequirements: Details;
  niceToHaveRequirements: Details;
  interviewProcess: Details;
};

type DescriptionBlock = {
  blockTitle: string;
  details: Details;
  as: ElementType;
};

enum Element {
  ul = "ul",
  ol = "ol",
}

const elementType: { [key in Element]: ElementType } = {
  [Element.ul]: Element.ul,
  [Element.ol]: Element.ol,
};

const useDescriptionBlocks = ({
  responsibilities,
  minimumRequirements,
  preferredRequirements,
  niceToHaveRequirements,
  interviewProcess,
}: DescriptionBlocksData) => {
  const { formatMessage } = useIntl();
  const descriptionBlocks = useMemo<Array<DescriptionBlock>>(
    () =>
      [
        {
          blockTitle: formatMessage({ id: "CAREER_POSITION_RESPONSIBILITIES" }),
          details: responsibilities,
          as: elementType.ul,
        },
        {
          blockTitle: formatMessage({ id: "CAREER_POSITION_REQUIREMENTS_MINIMUM" }),
          details: minimumRequirements,
          as: elementType.ul,
        },
        {
          blockTitle: formatMessage({ id: "CAREER_POSITION_REQUIREMENTS_PREFERRED" }),
          details: preferredRequirements,
          as: elementType.ul,
        },
        {
          blockTitle: formatMessage({ id: "CAREER_POSITION_REQUIREMENTS_NICETOHAVE" }),
          details: niceToHaveRequirements,
          as: elementType.ul,
        },
        {
          blockTitle: formatMessage({ id: "CAREER_POSITION_INTERVIEWPROCESS" }),
          details: interviewProcess,
          as: elementType.ol,
        },
      ].filter((list) => list.details && list.details.length > 0),
    [
      formatMessage,
      responsibilities,
      minimumRequirements,
      preferredRequirements,
      niceToHaveRequirements,
      interviewProcess,
    ]
  );

  return descriptionBlocks;
};

export { useDescriptionBlocks };
